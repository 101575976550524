
import Footer from '@/components/Footer.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { testimonials } from '../slides/testimonials';
import { ScreenSize } from '../interfaces/screen-size';

@Component({
    name: 'Home',
    components: {
        Footer,
        Carousel3d,
        Slide,
    },
})
export default class Home extends Vue {
    prevHtml = '<div class="w-12 h-8 ml-0 select-left-icon lg:ml-10 lg:h-16 lg:w-12"></div>';
    nextHtml = '<div class="w-12 h-8 mr-0 select-right-icon lg:mr-10 lg:h-16 lg:w-12"></div>';
    sliderWidth = 350;
    sliderHeight = 530;
    controlsWidth = 48;
    controlsHeight = 36;
    testimonials = testimonials;

    mounted() {
        this.moveCarouselControls();
        this.onScreenSizeChanged();
    }

    private moveCarouselControls(remove = false) {
        const carousel3dcontrols = document.querySelectorAll('.carousel-3d-controls');
        if (remove) {
            carousel3dcontrols[0].removeAttribute('style');
            return;
        }

        if (carousel3dcontrols.length > 0) {
            carousel3dcontrols[0].setAttribute(
                'style',
                'margin-top: 144px; margin-left: calc((100% - 350px) / 2); margin-right: calc((100% - 350px) / 2); width: 350px;'
            );
        }
    }

    @Watch('$store.state.screenSize')
    onScreenSizeChanged() {
        switch (this.$store.state.screenSize) {
            case ScreenSize.small:
                this.sliderWidth = 350;
                this.sliderHeight = 530;
                this.controlsWidth = 48;
                this.controlsHeight = 36;
                this.moveCarouselControls();
                break;
            case ScreenSize.medium:
                this.sliderWidth = 350;
                this.sliderHeight = 530;
                this.controlsWidth = 48;
                this.controlsHeight = 36;
                this.moveCarouselControls(true);
                break;
            default:
                this.sliderWidth = 700;
                this.sliderHeight = 450;
                this.controlsWidth = 80;
                this.controlsHeight = 80;
                this.moveCarouselControls(true);
                break;
        }
    }

    getImage(photoUrl: string) {
        return require(photoUrl);
    }
}
