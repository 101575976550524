
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMenuDefinition } from '../interfaces/menu-definition';
import TransitionExpand from './TransitionExpand.vue';

@Component({
    name: 'ExpandableMenu',
    components: {
        TransitionExpand,
    },
})
export default class ExpandableMenu extends Vue {
    @Prop() private menuDefinition!: IMenuDefinition | null;
}
