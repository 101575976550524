
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    public copyTextToClipboard() {
        const copyText = document.getElementById('email') as HTMLInputElement;
        copyText.removeAttribute('disabled');

        if (!copyText) {
            return;
        }

        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand('copy');
        copyText.setAttribute('disabled', '');

        /* Clear selection */
        const selection = window.getSelection();
        if (!selection) {
            return;
        }
        selection.removeAllRanges();
    }
}
