import { IMenuDefinition } from '@/interfaces/menu-definition';

export const servicesMenuDefinition: IMenuDefinition = {
    sections: [
        {
            id: 1,
            title: 'Fizioterapie',
            items: [
                {
                    id: 1,
                    displayName: 'Terapie TECARE',
                    routerUrl: '/servicii/terapie-tecar/',
                },
                {
                    id: 2,
                    displayName: 'Laser inalta frecventa',
                    routerUrl: '/servicii/laser-inalta-frecventa/',
                },
                {
                    id: 3,
                    displayName: 'Crioterapie',
                    routerUrl: '/servicii/crioterapie/',
                },
                {
                    id: 4,
                    displayName: 'Electroterapie',
                    routerUrl: '/servicii/electroterapie/',
                },
                {
                    id: 5,
                    displayName: 'Ultrasunet',
                    routerUrl: '/servicii/ultrasunet/',
                },
                {
                    id: 6,
                    displayName: 'Drenaj Limfatic',
                    routerUrl: '/servicii/drenaj-limfatic/',
                },
            ],
        },
        {
            id: 2,
            title: 'Evaluare aparat locomotor',
            items: [
                {
                    id: 1,
                    displayName: 'Testing Muscular',
                    routerUrl: '/servicii/testing-muscular/',
                },
                {
                    id: 2,
                    displayName: 'Testing Articular',
                    routerUrl: '/servicii/testing-articular/',
                },
            ],
        },
        {
            id: 3,
            title: 'Refacere Post Efort',
            items: [
                {
                    id: 1,
                    displayName: 'Crioterapie',
                    routerUrl: '/servicii/crioterapie/',
                },
                {
                    id: 2,
                    displayName: 'Stretching',
                    routerUrl: '/servicii/stretching/',
                },
                {
                    id: 3,
                    displayName: 'Terapie Manuala',
                    routerUrl: '/servicii/terapie-manuala/',
                },
                {
                    id: 4,
                    displayName: 'Masaj terapeutic',
                    routerUrl: '/servicii/masaj-terapeutic/',
                },
                {
                    id: 5,
                    displayName: 'Presoterapia',
                    routerUrl: '/servicii/recovery-boots/',
                },
            ],
        },
        {
            id: 4,
            title: 'Recuperare Medicala',
            items: [
                {
                    id: 1,
                    displayName: 'Kinetoterapie',
                    routerUrl: '/servicii/kinetoterapie/',
                },
                {
                    id: 2,
                    displayName: 'Kinetoterapie in Traumatologie Sportiva',
                    routerUrl: '/servicii/kinetoterapie-in-traumatologie-sportiva/',
                },
                {
                    id: 3,
                    displayName: 'Kinesio Taping',
                    routerUrl: '/servicii/kinesio-taping/',
                },
                {
                    id: 4,
                    displayName: 'Medical Flossing',
                    routerUrl: '/servicii/medical-flossing/',
                },
                {
                    id: 5,
                    displayName: 'Dry Needling',
                    routerUrl: '/servicii/dry-needling/',
                },
                {
                    id: 6,
                    displayName: 'Dynamic Tape',
                    routerUrl: '/servicii/dynamic-tape/',
                },
                {
                    id: 7,
                    displayName: 'Bandajare Rigida',
                    routerUrl: '/servicii/bandajare-rigida/',
                },
            ],
        },
    ],
};
