import Vue from 'vue'
import Vuex from 'vuex'
import { ScreenSize } from '@/interfaces/screen-size';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    screenSize: ScreenSize.small
  },
  mutations: {
    updateScreenSize(state, screenSize) {
      state.screenSize = screenSize;
    }
  },
  actions: {
  },
  modules: {
  }
})
