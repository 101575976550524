
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ExpandableMenu from '@/components/ExpandableMenu.vue';
import HamburgerMenu from '@/components/HamburgerMenu.vue';
import { IDefinitionLink, IMenuDefinition } from '@/interfaces/menu-definition';
import { servicesMenuDefinition } from '@/menu-definitions/services-menu-definition';
import { technologiesMenuDefinition } from '@/menu-definitions/technologies-menu-definition';
import TransitionExpand from './TransitionExpand.vue';
import TransitionFade from './TransitionFade.vue';
import { ScreenSize } from '@/interfaces/screen-size';

@Component({
    name: 'Header',
    components: {
        ExpandableMenu,
        HamburgerMenu,
        TransitionExpand,
        TransitionFade,
    },
})
export default class Header extends Vue {
    @Prop() private title!: string;
    private servicesDefinition: IMenuDefinition | null = null;
    private technologiesDefinition: IMenuDefinition | null = null;
    private menuDefinition: IMenuDefinition | null = null;
    public open = false;
    public links = [...this.getMenuLinks(servicesMenuDefinition), ...this.getMenuLinks(technologiesMenuDefinition)];

    public get isMobile() {
        return this.$store.state.screenSize === ScreenSize.small || this.$store.state.screenSize === ScreenSize.medium;
    }

    mounted() {
        this.resizeHeader();
        window.addEventListener('scroll', this.resizeHeader);
    }

    @Watch('$route')
    onRouteChanged() {
        this.resetMenuDefinitions();
        this.open = false;
        document.body.style.position = 'relative';
    }

    @Watch('isMobile')
    onIsMobileChanged() {
        this.resetMenuDefinitions();

        if (this.open && this.isMobile) {
            document.body.style.position = 'fixed';
        } else {
            document.body.style.position = 'relative';
        }
    }

    @Watch('open')
    onOpenChanged() {
        if (this.open && this.isMobile) {
            document.body.style.position = 'fixed';
        } else {
            document.body.style.position = 'relative';
        }
    }

    private getMenuLinks(menuDefinition: IMenuDefinition): IDefinitionLink[] {
        const menuLinks: IDefinitionLink[] = [];
        for (const section of menuDefinition.sections) {
            for (const item of section.items) {
                menuLinks.push(item);
            }
        }
        return menuLinks;
    }

    private resetMenuDefinitions() {
        this.menuDefinition = null;
        this.servicesDefinition = null;
        this.technologiesDefinition = null;
    }

    private resizeHeader() {
        const header = document.getElementById('header');
        const logo = document.getElementById('logo');
        const nav = document.getElementById('nav');
        if (!header || !logo || !nav) {
            return;
        }
        const headerClasses = header.classList;
        const logoClasses = logo.classList;
        const navClasses = nav.classList;
        if (document.documentElement.scrollTop >= 50) {
            headerClasses.add('lg:h-20');
            headerClasses.add('lg:border-b-2');
            logoClasses.add('lg:w-48');
            navClasses.add('lg:text-base');

            headerClasses.remove('lg:h-32');
            headerClasses.remove('lg:border-b-0');
            logoClasses.remove('lg:w-64');
            navClasses.remove('lg:text-lg');

            if (!headerClasses.contains('shrink')) {
                headerClasses.add('shrink');
            }
        } else {
            headerClasses.add('lg:h-32');
            headerClasses.add('lg:border-b-0');
            logoClasses.add('lg:w-64');
            navClasses.add('lg:text-lg');

            headerClasses.remove('lg:h-20');
            headerClasses.remove('lg:border-b-2');
            logoClasses.remove('lg:w-48');
            navClasses.remove('lg:text-base');

            if (headerClasses.contains('shrink')) {
                headerClasses.remove('shrink');
            }
        }
    }

    public toggleServicesMenu() {
        if (this.isServicesMenuOpen()) {
            this.menuDefinition = null;
            this.servicesDefinition = null;
        } else {
            if (!this.isMobile) {
                this.resetMenuDefinitions();
            }
            this.servicesDefinition = servicesMenuDefinition;
            this.menuDefinition = this.servicesDefinition;
        }
    }

    public toggleTechnologiesMenu() {
        if (this.isTechnologiesMenuOpen()) {
            this.menuDefinition = null;
            this.technologiesDefinition = null;
        } else {
            if (!this.isMobile) {
                this.resetMenuDefinitions();
            }
            this.technologiesDefinition = technologiesMenuDefinition;
            this.menuDefinition = this.technologiesDefinition;
        }
    }

    public isTechnologiesMenuOpen() {
        return this.technologiesDefinition != null;
    }

    public isServicesMenuOpen() {
        return this.servicesDefinition != null;
    }

    public isAnyMenuOpen() {
        return this.isServicesMenuOpen() || this.isTechnologiesMenuOpen();
    }

    public toggle() {
        this.open = !this.open;
    }
}
