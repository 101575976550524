import { render, staticRenderFns } from "./TransitionFade.vue?vue&type=template&id=58f65330&scoped=true"
import script from "./TransitionFade.vue?vue&type=script&lang=ts"
export * from "./TransitionFade.vue?vue&type=script&lang=ts"
import style0 from "./TransitionFade.vue?vue&type=style&index=0&id=58f65330&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f65330",
  null
  
)

export default component.exports