
import Header from "@/components/Header.vue";
import { Vue, Component } from 'vue-property-decorator';
import { ScreenSize } from '@/interfaces/screen-size';

@Component({
  name: "App",
  components: {
    Header
  }
})
export default class App extends Vue {
  mounted() {
    this.onResize.bind(this);
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  private onResize() {
    if (window.innerWidth < 768) {
      if (this.$store.state.screenSize !== ScreenSize.small) {
        this.$store.commit('updateScreenSize', ScreenSize.small);
      }
    } else if (window.innerWidth < 1024) {
      if (this.$store.state.screenSize !== ScreenSize.medium) {
        this.$store.commit('updateScreenSize', ScreenSize.medium);
      }
    } else if (window.innerWidth < 1280) {
      if (this.$store.state.screenSize !== ScreenSize.large) {
        this.$store.commit('updateScreenSize', ScreenSize.large);
      }
    } else {
      if (this.$store.state.screenSize !== ScreenSize.extraLarge) {
        this.$store.commit('updateScreenSize', ScreenSize.extraLarge);
      }
    }
  }
}
