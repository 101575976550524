export const testimonials = [
    {
        id: 0,
        text: 'Sunt sportiv de performanță practicând polo pe apă, un sport în care accidentările trenului \
        superior în special la umăr sunt foarte frecvente. Echipa MovKinetic sunt profesioniști desăvârșiți, \
        iar Ștefan owner-ul cabinetului mi-a tratat atent afecțiunea pe care o aveam folosind aparate de ultimă \
        generație de care dispune în cabinet. În doar câteva ședințe de terapie durerile au început să se atenueze, \
        iar umărul cu probleme își recupera din mobilitate. Am ales să vin în continuare la MovKinetic deoarece pe lângă \
        experiența vastă a kinetoterapeuților beneficiez de recuperare folosind aparatura ultra modernă din cabinet. \
        MovKinetic este mai mult decât o clinică de recuperare medicală, este un partener în activitatea mea profesională.',
        photoUrl: 'alexandru-ghiban.png',
        personName: 'Alexandru Ghiban ',
        personTitle: 'jucător de polo pe apă <br />Steaua București - echipa Națională a României'
    },
    {
        id: 1,
        text: 'Experiența mea la MovKinetic este una foarte plăcută. Ești înconjurat de experți care îți oferă o recuperare \
        corectă și în același timp este plăcut să-ți petreci timpul în cabinet datorită atmosferei! Totodată, beneficiezi de \
        multe aparate de recuperare de ultima generație. În cazul în care îmi apar probleme, știu mereu că mă pot baza pe echipa MovKinetic. \
        Recomand cu multă încredere acest cabinet de recuperare medicală.',
        photoUrl: 'tudor-fulea.png',
        personName: 'Tudor Fulea',
        personTitle: 'jucător de polo pe apă <br /> Steaua București - echipa Națională a României'
    },
    {
        id: 2,
        text: 'Colaborarea cu MovKinetic a început încă din anul 2017, iar din acel moment mi-am schimbat percepția despre sport. Am aflat de la \
        kinetoterapeuții MovKinetic că recuperarea medicală trebuie să facă parte din viața oricărui sportiv de performanță în primul rând pentru \
        a preveni accidentările. MovKinetic înseamnă aparatură de ultimă generație, profesionalism, dedicare, pasiune dar și plăcere si voie buna \
        pentru că în cabinetul MovKinetic nu se lucrează decât cu zâmbetul pe buze.',
        photoUrl: 'mihnea-gheorghe.png',
        personName: 'Mihnea Gheorghe',
        personTitle: 'jucător de polo pe apă <br /> Steaua București - echipa Națională a României'
    },
    {
        id: 3,
        text: 'Fiind sportiv de performanța o recuperare rapidă si eficientă este mai mult decât esențială pentru traseul nostru către victorie. \
        Movkinetic face parte din viața mea de mai bine de 4 ani si încă de la prima ședința am simțit implicarea, dedicarea, sprijinul fizic si \
        moral pe care echipa este gata sa îl ofere în fiecare secunda, chiar si în ziua de azi. \
        Pregătiți să te ajute si să găsească cea mai bună soluție privind recuperarea pe care o vei face pas cu pas cu ei, vă pot spune că reușita este garantată.',
        photoUrl: 'cotuna-vlad-bogdan.png',
        personName: 'Cotuna Vlad Bogdan',
        personTitle: 'medaliat european la gimnastică <br /> component Lot Olimpic România'
    },
    {
        id: 4,
        text: 'Recomand cu încredere echipa MovKinetic și mă bucur că au fost alături de mine în recuperarea post operație. \
        O echipă formată din profesioniști și oameni care pun pasiune în munca lor.',
        photoUrl: 'mihaela-buzarnescu.png',
        personName: 'Mihaela Buzărnescu',
        personTitle: 'jucătoare profesionistă de tenis'
    }
    ,{
        id: 5,
        text: 'De-a lungul carierei mele în sportul de performanță, am avut deseori accidentări, însă, de câte ori aveam o \
        problema știam unde merg să o rezolv, la băieții de la Movkinetic, că era Ștefan sau Alex, mereu am fost pe mâini\
        bune și m-au ajutat și cu recuperare și cu sfaturi pentru prevenție!\
        Îmi place că aici găsesc cele mai noi tehnologii în recuperarea sportivă, și nu numai! Pe aceasta cale vreau sa vă mulțumesc \
        și tot odată să vă felicit pentru muncă și dedicare!',
        photoUrl: 'andreea-panturoiu.png',
        personName: 'Andreea Panturoiu',
        personTitle: 'atletism(componenta a lotului national al Romaniei)'
    }
    ,{
        id: 6,
        text: 'MovKinetic este locul în care orice problemă are o rezolvare si datorită aparaturii de înaltă calitate si a băieților \
         care sunt foarte profesionisti si stiu cum să lucreze cu oamenii, totul face ca recuperarea de după o accidentare \
         sa pară foarte rapidă si usoară! ',
        photoUrl: 'placeholder.png',
        personName: 'Nechita Claudia',
        personTitle: 'box – locul 5 Jocurile Olimpice, multiplu campioana nationala'
    }
    ,{
        id: 7,
        text: 'Cand esti un sportiv  pasionat si dedicat sportului, pentru a reusi ai nevoie in jurul tau  de o echipa \
        de  oameni profesioniști. Movkinetic a fost alegerea mea atunci cand accidentarile au început sa apară. O echipa\
         de oameni profesioniști, pasionați si dedicați meseriei lor si mereu   interesul lor  principal este refacerea \
         cat mai rapida a sportivilor si nu numai.',
        photoUrl: 'placeholder.png',
        personName: 'Lăcrămioara Perijoc',
        personTitle: 'box- campioana europeana, medaliata mondiala'
    }
    ,{
        id: 11,
        text: 'Ma bucur ca am putut beneficia de ajutorul echipei Movkinetic pe tot parcursul recuperarii ,dupa o \
        interventie chirurgicala ce credeam ca ma va da in spate din calea performantei ! Am avut parte de o experienta\
         deosebita alaturi de oameni de nadejde ce m au adus acum pe calea cea buna . Va multumesc pentru tot ajutorul \
         acordat , datorita voua obiectivele mele vor deveni realitate !',
        photoUrl: 'roxana-capezan.png',
        personName: 'Roxana Capezan',
        personTitle: 'lupte- componenta a lotului national al Romaniei'
    } 
    ,{
        id: 9,
        text: 'Alaturi de echipa Movkinetic am invatat lucruri noi , ce sunt sigura ca ma vor ajuta in cariera sportiva . \
        Am avut parte de o experienta placuta si tin sa va multumesc pentru tot ajutorul acordat !',
        photoUrl: 'ana-andreea.png',
        personName: 'Ana Andreea',
        personTitle: 'lupte- campioana europeana , campioana mondiala U23'
    }
    ,{
        id: 10,
        text: 'Pentru o recuperare rapida si profesionista eu am ales echipa MovKinetic. \
        Sunt sportiva de performanta in gimnastica artistica si pot spune ca am intampinat o multime de probleme de sanatate. \
        De cand vin in cabinetul de kineto-terapie al echipei Movkinetic sunt foarte multumita de eficienta recuperari \
        si vibe-ul bun pe care le am avut de fiecare data cand am reusit sa revin in sala de antrenament fara durere. \
        MovKinetic este parte din procesul indepliniri unui obiectiv important.',
        photoUrl: 'marian-dragulescu.png',
        personName: 'Marian Dragulescu',
        personTitle: 'gimnastica artistica-multiplu campion mondial si European, multiplu medaliat olimpic'
    },
    {
        id: 8,
        text: 'Pentru o recuperare rapida si profesionista eu am ales echipa MovKinetic. \
        Sunt sportiva de performanta in gimnastica artistica si pot spune ca am intampinat o multime de probleme de sanatate.\
        De cand vin in cabinetul de kineto-terapie al echipei Movkinetic sunt foarte multumita de eficienta recuperari \
         si vibe-ul bun pe care le am avut de fiecare data cand am reusit sa revin in sala de antrenament fara durere.\
        MovKinetic este parte din procesul indepliniri unui obiectiv important.',
        photoUrl: 'larisa-iordache.png',
        personName: 'Larisa Iordache',
        personTitle: 'gimnastica artistica-multipla campioana europeana, medaliata olimpica si mondiala'
    },
    {
        id: 12,
        text: 'La Movkinetic m-am tratat de rupturi musculare, hernie de disc, inflamații și \
        multe alte probleme medicale care fac parte din sportul de performanță. \
        Movkinetic mi-a prelungit viața sportivă cu câțiva ani buni. Mulțumesc băieți!',
        photoUrl: 'placeholder.png',
        personName: 'Marius Dumitrache',
        personTitle: 'campion al Romaniei  (Atletism-Inaltime)'
    },
    {
        id: 13,
        text: 'Sunt un alergator amator, pasionat de alergarea montana si de distantele luni. Foarte lungi. Sunt de asemenea\
        si mandrul posesor al unor glezne de portelan. Ca si consecinta, am reusit de cateva ori sa ma accidentez destul de\
        serios si doar o minune a facut sa nu ajung la operatie. Am ajuns in schimb la Movkinetic, pe mainile sui Stefan si\
        Alex; la ultima «isprava » au reusit ca in 10 zile sa ma faca sa visez iar la Leadville 100, o cursa legendara de 100\
        de mile in inimal Americii, dupa ce am ajuns la ei in carje. Modul direct in care iti explica ce trebuie\
        sa faci, deschiderea catre ceea ce te intereseaza si pe tine si nu in ultimul rand, simtul umorului, au fost\
        bazele unei relatii de prietenie cu picioare solide. \
        Multumesc mult baieti si sper sa nu va vad prea des !',
        photoUrl: 'placeholder.png',
        personName: 'Marian Vasile',
        personTitle: 'alergator amator( alergare montane si distante foarte lungi)'
    },
    {
        id: 14,
        text: 'Calitate nu cantitate,, pe scurt MovKinetic . Din punctul meu de vedere cel mai bun centru de \
        recuperare din Bucuresti , un loc unde sportivi și oameni obișnuiți au parte de cele mai bune mijloace \
        de recuperare , unde doi oameni deosebiți se ocupa zilnic de îngrijirea și recuperarea pacienților . \
        Un loc unde ma simt bine și vin cu drag de fiecare data când am ocazia !',
        photoUrl: 'placeholder.png',
        personName: 'Robert Rizea',
        personTitle: 'fotbalist profesionist liga1, liga 2'
    },
    {
        id: 15,
        text: 'Sportiva de performanta de 12 ani. M-am confruntat cu multe accidentari de-a lungul vietii mele de sportiva , \
        iar cabinetul Movkinetic a fost primul care mi-a sarit in ajutor. Imi amintesc cand am avut prima mea accidentare \
        acum aproximativ 5 ani, unde am fost la mai multi kinetoterapeuti pentru recuperare, acestia neputand sa ma refaca bine. \
        Apoi am descoperit cabinetul movkinetic unde m a ajutat sa trec peste acel moment neplacut, iar de asemenea peste toate \
        accidentarile de care am mai avut parte . Sunt extrem de multumita si de recunoscatoare de oamenii din echipa Movkineti',
        photoUrl: 'placeholder.png',
        personName: 'Maria Mihalache',
        personTitle: 'atleta(100m si 200m ), locul 9 CE junior, Locul 9 CM junior, multipla medaliata nationala'
    }

];