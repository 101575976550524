export enum metaType {
    home = '/',
    about = '/despre-noi/',
    contact = '/contact/',
    gallery = '/galerie-foto/',
    terapieTecarService = '/servicii/terapie-tecar/',
    laserInaltaFrecventaService = '/servicii/laser-inalta-frecventa/',
    crioterapieService = '/servicii/crioterapie/',
    electroterapieService = '/servicii/electroterapie/',
    ultrasunetService = '/servicii/ultrasunet/',
    drenajLimfaticService = '/servicii/drenaj-limfatic/',
    testingMuscularService = '/servicii/testing-muscular/',
    testingArticularService = '/servicii/testing-articular/',
    stretchingService = '/servicii/stretching/',
    terapieManualaService = '/servicii/terapie-manuala/',
    masajTerapeuticService = '/servicii/masaj-terapeutic/',
    recoveryBootsService = '/servicii/recovery-boots/',
    kinetoterapieService = '/servicii/kinetoterapie/',
    kinetoterapieTraumatologieSportivaService = '/servicii/kinetoterapie-in-traumatologie-sportiva/',
    kinesioTapingService = '/servicii/kinesio-taping/',
    medicalFlossingService = '/servicii/medical-flossing/',
    dryNeedlingService = '/servicii/dry-needling/',
    dynamicTapeService = '/servicii/dynamic-tape/',
    bandajareRigidaService = '/servicii/bandajare-rigida/',
    desmotecTech = '/tehnologii/desmotec/',
    gameReadyTech = '/tehnologii/game-ready/',
    theragunProTech = '/tehnologii/theragun-pro/',
    terapieTecarDiacareTech = '/tehnologii/terapie-tecar-diacare/',
    kineoIntelligentTech = '/tehnologii/kineo-intelligent/',
    laserInaltaFrecventaTechTech = '/tehnologii/laser-inalta-frecventa/',
}

function reusableMetaTags(path: string) {
    return [
        {
            name: 'author',
            content: 'MovKinetic',
        },
        {
            property: 'og:image',
            content: 'https://movkinetic.ro/img/logo_galben300.png',
        },
        {
            property: 'og:site_name',
            content: 'MovKinetic',
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:locale',
            content: 'ro_RO',
        },
        {
            property: 'og:url',
            content: `https://www.movkinetic.ro${path}`,
        },
    ];
}

function getMetaByPath(path: string, title: string, description: string, keywords?: string) {
    return {
        title: title,
        metaTags: [
            {
                name: 'description',
                content: description,
            },
            {
                name: 'keywords',
                content: keywords
                    ? keywords
                    : 'movkinetic, fizioterapie, kinetoterapie, fizio, kineto, recuperare, medicala',
            },
            {
                property: 'og:description',
                content: description,
            },
            {
                property: 'og:title',
                content: title,
            },
            ...reusableMetaTags(path),
        ],
    };
}

function defaultMeta(path: string) {
    return getMetaByPath(
        path,
        'Clinica Recuperare Medicala - Cabinet Kinetoterapie MovKinetic Bucuresti',
        'MovKinetic este un centru de recuperare medicala specializat in traumatologie sportiva, ce utilizeaza tehnologii si aparate medicale de ultima generatie.'
    );
}

export function getMeta(type: metaType = metaType.home) {
    switch (type) {
        case metaType.home: {
            return defaultMeta(type);
        }
        case metaType.about: {
            return getMetaByPath(
                type,
                'Echipa clinicii de recuperare medicala MovKinetic',
                'Noi, Echipa MovKinetic, suntem experti in domeniul fizioterapiei si specializati in traumatologie sportiva. Peste 4000 de pacienti ne-au trecut pragul.'
            );
        }
        case metaType.contact: {
            return getMetaByPath(
                type,
                'Clinica de recuperare medicala MovKinetic - Contact',
                'Pentru mai multe informatii cu privire la serviciile medicale oferite de clinica MovKinetic, va rugam sa utilizati datele de contact aflate pe site.'
            );
        }
        case metaType.gallery: {
            return getMetaByPath(
                type,
                'Clinica de recuperare medicala MovKinetic - Galerie Foto',
                'Galerie foto cu sportivi, exercitii si terapii de la clinica de recuperare medicala MovKinetic, dedicata practicantilor de sport.'
            );
        }
        case metaType.terapieTecarService: {
            return getMetaByPath(
                type,
                'Terapie Tecar la clinica de recuperare MovKinetic',
                'Terapia Tecar este o metoda inovativa de ultima generatie care stimuleaza vindecarea in scurt timp. Vezi beneficiile fizioterapiei Tecar acum la MovKinetic.'
            );
        }
        case metaType.laserInaltaFrecventaService: {
            return getMetaByPath(
                type,
                'Fizioterapie cu laser inalta frecventa - laserterapie MovKinetic',
                'Maximizeaza rezultatele terapeutice cu ajutorul terapiei cu laser de inalta frecventa. Vezi beneficiile laserterapiei acum la MovKinetic.'
            );
        }
        case metaType.crioterapieService: {
            return getMetaByPath(
                type,
                'Recuperare medicala prin Crioterapie - MovKinetic',
                'Combate spasmele si scade durerea cu ajutorul metodei complexe de vindecare precum crioterapia. Vezi beneficiile crioterapiei acum la clinica MovKinetic.'
            );
        }
        case metaType.electroterapieService: {
            return getMetaByPath(
                type,
                'Recuperare medicala prin Electroterapie - MovKinetic',
                'Reduce senzatiile de durere prin electroterapie, aceasta find efectuata prin stimulare electrica. Vezi beneficiile electroterapiei acum la clinica MovKinetic.'
            );
        }
        case metaType.ultrasunetService: {
            return getMetaByPath(
                type,
                'Recuperare medicala prin terapie cu ultrasunete - MovKinetic',
                'Solutia ideala pentru un efect analgezic este terapia cu ultrasunete. Vezi beneficiile fizioterapiei cu ultrasunete acum la MovKinetic.'
            );
        }
        case metaType.drenajLimfaticService: {
            return getMetaByPath(
                type,
                'Recuperare medicala prin drenaj limfatic - MovKinetic',
                'Recuperare prin drenaj limfatic la MovKinetic. Stimuleaza circulatia limfei prin masaj limfatic pentru reglarea si imbunatatirea circulatiei.'
            );
        }
        case metaType.testingMuscularService: {
            return getMetaByPath(
                type,
                'Testing Muscular la clinica de recuperare - MovKinetic',
                'Examinare manuala si digitala  pentru evaluarea fortei fiecarui musch, membru, grupe de muschii prin Testing Muscular pentru - clinica MovKinetic.'
            );
        }
        case metaType.testingArticularService: {
            return getMetaByPath(
                type,
                'Testing Articular la clinica de recuperare - MovKinetic',
                'Examinare manuala pentru evaluarea gradului de miscare al articulatiilor prin Testing Articular pentru alcatuirea unui program de recuperare la MovKinetic.'
            );
        }
        case metaType.stretchingService: {
            return getMetaByPath(
                type,
                'Refacere post efort prin Stretching - MovKinetic',
                'Stretching pentru elasticizarea musculatorii si mobilitate articulara - MovKinetic.'
            );
        }
        case metaType.terapieManualaService: {
            return getMetaByPath(
                type,
                'Refacere post efort prin Terapie Manuala - MovKinetic',
                'Reduce tensiunile, durerile musculare si articulare  prin terapie manuala.  La clinica MovKinetic, terapia manuala este combinata cu proceduri fizio sau tehnici de kineto.'
            );
        }
        case metaType.masajTerapeuticService: {
            return getMetaByPath(
                type,
                'Refacere post efort prin Masaj Terapeutic - MovKinetic',
                'Masaj terapeutic pentru dureri acute sau cronice de spate sau musculare. Scopul final al acestei terapii prin masaj difera in functie de afectiunea tratata.'
            );
        }
        case metaType.recoveryBootsService: {
            return getMetaByPath(
                type,
                'Refacere prin Presoterapie (Recovery Boots) - MovKinetic',
                'Presoterapia elimina toxinele drenajului limfatic si recupereaza picioarele dupa efort. Recovery Boots este o metoda moderna ce este utilizata la MovKinetic.'
            );
        }
        case metaType.kinetoterapieService: {
            return getMetaByPath(
                type,
                'Recuperare medicala prin Kinetoterapie - MovKinetic',
                'Restabileste anumite functii ale corpului prin Kinetoterapie. La centrul de kinetoterapie MovKinetic elaboram programe si tratamente individuale.'
            );
        }
        case metaType.kinetoterapieTraumatologieSportivaService: {
            return getMetaByPath(
                type,
                'Kinetoterapie in Traumatologie Sportiva - MovKinetic',
                'Kinetoterapia in traumatologie si trautomatologie sportiva urmareste restabilirea deficientilor fizice. La MovKinetic suntem specializati in traumatologie sportiva.'
            );
        }
        case metaType.kinesioTapingService: {
            return getMetaByPath(
                type,
                'Kinetoterapie prin Kinesio Taping - MovKinetic',
                'Kinesio Taping reduce semnificativ durerea printr-o metoda noua de a facilita miscarea. Descopera beneficiile recuperarii prin Kinesio Taping la MovKinetic.'
            );
        }
        case metaType.medicalFlossingService: {
            return getMetaByPath(
                type,
                'Kinetoterapie prin Medical Flossing - MovKinetic',
                'Medical Flossing este o tehnica de recuperare ce imbunatateste mobilitatea folosind o banda terapeutica elastica. Descopera beneficiile tehnicii Medical Flossing la clinica MovKinetic.'
            );
        }
        case metaType.dryNeedlingService: {
            return getMetaByPath(
                type,
                'Kinetoterapie prin Dry Needling (ace uscate)- MovKinetic',
                'Reduce semnificativ durerile musculare prin terapia acelor uscate. MovKinetic utilizeaza metoda dry needling pentru a reduce durerea chiar din prima sedinta.'
            );
        }
        case metaType.dynamicTapeService: {
            return getMetaByPath(
                type,
                'Kinetoterapie prin Dynamic Tape - MovKinetic',
                'Banda dinamica “Dynamic Tape” ajuta la gestionarea incarcarii fortei in tesut pentru a un exercitiu corect. La MovKinetic gasesti metode inovative de recuperare.'
            );
        }
        case metaType.bandajareRigidaService: {
            return getMetaByPath(
                type,
                'Bandajare Rigida la clinica de recuperare MovKinetic',
                'La clinica MovKinetic folosim metode variate de recuperare precum cea a badajarii rigide, pentru a le oferi pacientilor sustinerea musculare de care au nevoie.'
            );
        }
        case metaType.desmotecTech: {
            return getMetaByPath(
                type,
                'Tehnologia de recuperare medicala Desmotec - MovKinetic',
                'Dispozitivele Desmotec sunt intrumente High-Tech ce ajuta la consolidarea musculara si la testarea parametrilor muscularo si articulari ai corpului - MovKinetic.'
            );
        }
        case metaType.gameReadyTech: {
            return getMetaByPath(
                type,
                'Tehnologie de recuperare Game Ready - MovKinetic',
                'Game Ready ofera sisteme inovatoare de recuperare pentru sportivi. La clinica de recuperare MovKinetic reinventam recuperarea.'
            );
        }
        case metaType.theragunProTech: {
            return getMetaByPath(
                type,
                'Tehnologie de recuperare Theragun Pro - MovKinetic',
                'Theragun Pro, dispozitiv profesional utilizat in tratarea afectiunilor musculare. Vezi beneficiile aparatului de terapia percutia la clinica MovKinetic.'
            );
        }
        case metaType.terapieTecarDiacareTech: {
            return getMetaByPath(
                type,
                'Tehnologia de recuperare Tecar - MovKinetic',
                'Detinem unul dintre cele mai performante aparate Tecar. DiaCaRe 7000 find un aparat medical profesional ce il vezi gasi la clinica MovKinetic.'
            );
        }
        case metaType.kineoIntelligentTech: {
            return getMetaByPath(
                type,
                'Tehnologia de recuperarea Kineo Intelligent - MovKinetic',
                'Pentru o evaluare completa a intregului corp, tehnologia Kineo Intelligent este cea ideala. MovKinetic este prima clinica ce a adoptat aceasta tehnologie.'
            );
        }
        case metaType.laserInaltaFrecventaTechTech: {
            return getMetaByPath(
                type,
                'Tehnologia de recuperare prin Laser - MovKinetic',
                'Pentru fizioterapie si reabilitare folosim tehnologia cu laser de inalta frecventa. La MovKinetic utilizam cel mai perfomant aparat cu terapie laser, LUMIX 2.'
            );
        }
        default:
            return defaultMeta(metaType.home);
    }
}
