import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { getMeta, metaType } from './meta-tags';

Vue.use(VueRouter);

const routes = [
    // {
    //   path: "/*",
    //   name: "Maintenance",
    //   component: () => import("../views/Maintenance.vue"),
    //   meta: getMeta()
    // },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: getMeta,
    },
    {
        path: '/despre-noi/',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: getMeta,
    },
    {
        path: '/contact/',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: getMeta,
    },
    {
        path: '/galerie-foto/',
        name: 'PhotoGallery',
        component: () => import('../views/PhotoGallery.vue'),
        meta: getMeta,
    },
    {
        path: '/servicii/:id/',
        name: 'Services',
        component: () => import('../views/Services.vue'),
        meta: getMeta,
    },
    {
        path: '/tehnologii/:id/',
        name: 'Technologies',
        component: () => import('../views/Technologies.vue'),
        meta: getMeta,
    },
    {
        path: '/politica-cookies/',
        name: 'CookiesPolicy',
        component: () => import('../views/CookiesPolicy.vue'),
        meta: getMeta,
    },
    {
        path: '/termeni-si-conditii/',
        name: 'TermsAndConditions',
        component: () => import('../views/TermsAndConditions.vue'),
        meta: getMeta,
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('../views/404.vue'),
    },
];

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    const toPath = to.fullPath;
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested"s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => {
            const meta = r.meta(toPath);
            return meta && meta.title;
        });

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => {
            const meta = r.meta(toPath);
            return meta && meta.metaTags;
        });

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta(toPath).title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => {
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    });

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta
        .meta(toPath)
        .metaTags.map((tagDef) => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create, so we don"t interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.prepend(tag));

    next();
});

export default router;
