import { IMenuDefinition } from '@/interfaces/menu-definition';

export const technologiesMenuDefinition: IMenuDefinition = {
    sections: [
        {
            id: 1,
            title: '',
            items: [
                {
                    id: 1,
                    displayName: 'Desmotec',
                    routerUrl: '/tehnologii/desmotec/',
                },
                {
                    id: 2,
                    displayName: 'Kineo Intelligent Load',
                    routerUrl: '/tehnologii/kineo-intelligent/',
                },
                {
                    id: 3,
                    displayName: 'DIACARE 7000 - TECARE THERAPY',
                    routerUrl: '/tehnologii/terapie-tecar-diacare/',
                },
            ],
        },
        {
            id: 2,
            title: '',
            items: [
                {
                    id: 3,
                    displayName: 'Game Ready',
                    routerUrl: '/tehnologii/game-ready/',
                },
                {
                    id: 4,
                    displayName: 'Theragun PRO',
                    routerUrl: '/tehnologii/theragun-pro/',
                }, 
                {
                    id: 7,
                    displayName: 'Theal Therapy by Mectronic',
                    routerUrl: '/tehnologii/theal-therapy-by-mectronic/',
                },
                {
                    id: 8,
                    displayName: 'Fisioline Lumix LASER',
                    routerUrl: '/tehnologii/fisioline-lumix-laser/',
                },
                // {
                //     id: 6,
                //     displayName: 'Laser inalta frecventa',
                //     routerUrl: '/tehnologii/laser-inalta-frecventa/',
                // },
            ],
        },
    ],
};
