import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import './assets/styles/index.scss';
import './assets/styles/theme.scss';
import './assets/styles/fonts.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import VueScrollTo from 'vue-scrollto';
import { VueSpinners } from '@saeris/vue-spinners';
import VueGallery from 'vue-gallery';

library.add(fas);
library.add(fab);

Vue.config.productionTip = false;
Vue.use(Meta);
Vue.use(VueScrollTo)
Vue.use(VueSpinners);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('gallery', VueGallery);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
